<template>
  <div class="results-container">
    <h1>Documents</h1>
    <div v-if="files.length > 0" class="results-content">
      <div v-for="(file, index) in files" :key="index" class="file-item">
        <a :href="file.url" target="_blank" rel="noopener noreferrer">{{ file.filename }}</a>
      </div>
    </div>
    <div v-else>
      <p class="no-documents">The user is valid, but no documents were found.</p>
    </div>
    <button @click="logout" class="logout-button">Logout</button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'SearchResults',
  setup() {
    const router = useRouter();
    const files = ref([]);

    onMounted(() => {
      const storedFiles = sessionStorage.getItem('files');
      if (storedFiles) {
        try {
          files.value = JSON.parse(storedFiles);
          console.log("Parsed files:", files.value);
        } catch (error) {
          console.error('Error parsing files data:', error);
          files.value = [];
        }
      } else {
        console.log("No files data found");
        router.push({ name: 'SearchForm' });
      }
    });

    const logout = () => {
      // Clear the sessionStorage
      sessionStorage.removeItem('files');
      
      // Redirect to SearchForm
      router.push({ name: 'SearchForm' });
    };

    return { files, logout };
  },
};
</script>

<style scoped>
.results-container {
  background-color: #000;
  padding: 40px;
  border-radius: 8px;
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}

.results-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-item {
  margin-top: 15px;
  padding-top: 15px;
  color: white;
  border-top: 1px solid gray;
}

a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
}

.no-documents {
  color: #fff;
  font-size: 16px;
}

.logout-button {
  background-color: #32cd32;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #28a745;
}
</style>