<template>
  <div class="form-container">
    <h1>Retrieve Documents</h1>
    <form @submit.prevent="handleSubmit" class="form-content">
      <div class="input-group">
        <label for="code">Authorization Code:</label>
        <input type="text" v-model="code" required />
      </div>
      <div class="input-group">
        <label for="surname">Last Name:</label>
        <input type="text" v-model="surname" required />
      </div>
      <div class="input-group">
        <label for="firstname">First Name:</label>
        <input type="text" v-model="firstname" required />
      </div>
      <div class="input-group">
        <label for="ssn">Last 4 Digits of SSN/EIN:</label>
        <input type="text" v-model="ssn" maxlength="4" required />
      </div>
      <button type="submit" class="submit-button">Go</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'SearchPage',
  setup() {
    const code = ref('');
    const surname = ref('');
    const firstname = ref('');
    const ssn = ref('');
    const router = useRouter();

    onMounted(() => {
      const storedFiles = sessionStorage.getItem('files');
      if (storedFiles) {
        router.push({ name: 'SearchResults' });
      }
    });

    const handleSubmit = async () => {
      const response = await fetch(
        'https://a8tna9s8g3.execute-api.us-east-2.amazonaws.com/default/Theatricals42Documents',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: code.value,
            surname: surname.value,
            ssn: ssn.value,
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        alert("Hmm... we couldn't find you.");
      } else {
        // Store the files data in sessionStorage
        sessionStorage.setItem('files', JSON.stringify(data.files));
        router.push({ name: 'SearchResults' });
      }
    };

    return {
      code,
      surname,
      firstname,
      ssn,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.form-container {
  background-color: #000;
  padding: 40px;
  border-radius: 8px;
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  color: #fff;
  font-size: 0.9em;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 4px 8px;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.submit-button {
  background-color: #32cd32;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #28a745;
}
</style>