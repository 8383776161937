import { createRouter, createWebHistory } from 'vue-router';
import SearchForm from '../components/SearchForm.vue';
import SearchResults from '../components/SearchResults.vue';

const routes = [
  {
    path: '/',
    name: 'SearchForm',
    component: SearchForm,
    meta: { title: 'Retrieve Documents' },
  },
  {
    path: '/results',
    name: 'SearchResults',
    component: SearchResults,
    meta: { title: 'Your Documents' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add a global beforeEach guard to dynamically change the page title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Documents'; // Set the title or use a default
  next();
});

export default router;